import { useState } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBNavbar, MDBNavbarToggler, MDBIcon, MDBCollapse, MDBNavbarNav, MDBNavbarItem } from 'mdb-react-ui-kit';

function NavBar() {


  const [showNavCentred, setShowNavCentred] = useState(false);  
  
  return (
<>
    {/* <div className="d-flex justify-content-center" style={{backgroundColor: '#FBFBFB', width: '100%', paddingRight:'0px', height:'0px', zIndex:'10', borderBottom:'0.5rem solid #004b87'}}>
    </div> */}
    </>
  );
}

export default NavBar;