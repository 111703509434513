import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Navbar from '../component/navbar'
import logoGol from '../img/logo-golplus.svg'
import logoGolPlacas from '../img/logo-gol-placas.jpeg';
import logoDespachante from '../img/logo-despachante.jpeg';
import logoCnh from '../img/logo-cnh.jpeg';
import logoVistoria from '../img/logo-vistoria.jpeg';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardFooter } from 'mdb-react-ui-kit';
import '../index.css';

function Index() {
    const [messages, setMessages] = useState([{ type: 'bot', text: 'Olá! 😄' }, { type: 'bot', text: 'Como posso te ajudar hoje?' }]);
    const [inputMessage, setInputMessage] = useState('');
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);

    useEffect(() => {
        document.title = "Tree Veicular";
        window.scrollTo(0, 0);
    }, []);

    const sendMessage = async () => {
        if (!inputMessage) return;

        const newMessage = { type: 'user', text: inputMessage };
        setMessages(messages => [...messages, newMessage]);

        try {
            const formattedMessages = messages.map(msg => ({
                role: msg.type === 'user' ? 'user' : 'system',
                content: msg.text
            }));

            const res = await axios.post('https://tree-veicular.agenciaupstairs.com.br/API/index.php', {
                messages: formattedMessages,
                new_message: inputMessage
            });

            const reply = { type: 'bot', text: res.data.message };
            setMessages(messages => [...messages, reply]);

            // Incluir o link no chat, se presente na resposta
            if (res.data.serviceUrl) {
                const serviceMessage = { type: 'bot', text: `Confira aqui o serviço que você precisa: ${res.data.serviceUrl}` };
                setMessages(messages => [...messages, serviceMessage]);
            }
        } catch (error) {
            console.error("Erro ao enviar mensagem", error);
        }

        setInputMessage('');
    };



    return (
        <div style={{ backgroundColor: '#7d56c0', paddingBottom: '5rem' }}>
            <Navbar />

            <MDBContainer>

                <MDBRow className='justify-content-center' style={{ paddingTop: '2rem' }}>
                    <MDBCol md={6}>
                        <MDBCard style={{ borderRadius: '15px' }}>
                            <MDBCardTitle style={{ borderBottom: 'solid 1px #e6e6e6', padding: '1rem 1rem', paddingBottom: '0rem' }}>
                                <h1 style={{ fontSize: '1.43rem', fontWeight: 700, }}>Tree Veicular AI </h1>
                                <p style={{ fontSize: '0.95rem', fontWeight: 600 }}>Localize o serviço que você precisa!</p>
                            </MDBCardTitle>
                            <MDBCardBody className='card-chat'>
                                {messages.map((msg, index) => {
                                    // Extrair URL da mensagem
                                    const urlMatch = msg.text.match(/https?:\/\/[^\s]+/);
                                    const url = urlMatch ? urlMatch[0] : null;

                                    return (
                                        <p key={index} className={`message ${msg.type === 'user' ? 'message-user' : 'message-bot'}`}>
                                            {url
                                                ? <span>{msg.text.split(url)[0]}<a href={url} target="_blank" rel="noopener noreferrer">{url}</a></span>
                                                : msg.text
                                            }
                                        </p>
                                    );
                                })}
                            </MDBCardBody>


                            <MDBCardFooter>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <input
                                        type="text"
                                        value={inputMessage}
                                        onChange={e => setInputMessage(e.target.value)}
                                        placeholder='Digite sua dúvida'
                                        onKeyPress={event => event.key === 'Enter' ? sendMessage() : null}
                                        className="message-input"
                                    />
                                    <button onClick={sendMessage} className="send-button">Enviar</button>
                                </div>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>


            <MDBContainer className="" style={{ marginTop: '70px', paddingBottom: '70px', padding: '0' }}>
                <div style={{ width: '100%', backgroundColor: '#1e1e1e', height: '0.5rem', marginBottom: '5px' }}></div>
                <h1 style={{ color: '#e1e1e1', fontWeight: '800', fontSize: '2rem', textTransform: 'uppercase', textAlign: 'left', padding: '15px', backgroundColor: '#1e1e1e', width: '50%', borderBottomRightRadius: '25px' }}>
                    Conheça todos os nossos serviços!
                </h1>
                <MDBRow style={{ margin: '0px', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                    <MDBCard className='cardsServices hover-shadow' onClick={() => window.location.href = 'https://gol-plus.agenciaupstairs.com.br'}>
                        <MDBRow>
                            <MDBCol md={3} style={{ backgroundImage: 'linear-gradient(to right, #1e1e1e20 10%, #fbfbfb 90%)', padding: '20px' }}>
                                <img src={logoGol} style={{ maxWidth: '120px', textAlign: 'center' }} />
                            </MDBCol>
                            <MDBCol style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <h1>Gol Plus ABC</h1>
                                <p>Serviços de seguro para carros é com a Gol Plus ABC!</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                    <MDBCard className='cardsServices hover-shadow'>
                        <MDBRow>
                            <MDBCol md={3} style={{ backgroundImage: 'linear-gradient(to right, #1e1e1e20 10%, #fbfbfb 90%)', padding: '20px' }}>
                                <img className='border-50-rounded' src={logoGolPlacas} style={{ maxWidth: '120px', textAlign: 'center' }} />
                            </MDBCol>
                            <MDBCol style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <h1>Gol Placas</h1>
                                <p>Serviços de venda e instalação de placas Mercosul!</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                    <MDBCard className='cardsServices hover-shadow'>
                        <MDBRow>
                            <MDBCol md={3} style={{ backgroundImage: 'linear-gradient(to right, #1e1e1e20 10%, #fbfbfb 90%)', padding: '20px' }}>
                                <img className='border-50-rounded' src={logoVistoria} style={{ maxWidth: '120px', textAlign: 'center' }} />
                            </MDBCol>
                            <MDBCol style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <h1>Vistoria Central</h1>
                                <p>Vistoria de veículos para venda, compra de veículos e transferências!</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                    <MDBCard className='cardsServices hover-shadow'>
                        <MDBRow>
                            <MDBCol md={3} style={{ backgroundImage: 'linear-gradient(to right, #1e1e1e20 10%, #fbfbfb 90%)', padding: '20px' }}>
                                <img className='border-50-rounded' src={logoDespachante} style={{ maxWidth: '120px', textAlign: 'center' }} />
                            </MDBCol>
                            <MDBCol style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <h1>Despachante.net</h1>
                                <p>Serviços de despachante em geral!</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                    <MDBCard className='cardsServices hover-shadow'>
                        <MDBRow>
                            <MDBCol md={3} style={{ backgroundImage: 'linear-gradient(to right, #1e1e1e20 10%, #fbfbfb 90%)', padding: '20px' }}>
                                <img className='border-50-rounded' src={logoCnh} style={{ maxWidth: '120px', textAlign: 'center' }} />
                            </MDBCol>
                            <MDBCol style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <h1>De volta CNH</h1>
                                <p>Serviços para recuperação da CNH!</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                </MDBRow>
            </MDBContainer>
        </div>
    )
}

export default Index;